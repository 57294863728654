<template>
  <v-container fill-height>
    <h1>Terms</h1>
    <p>
      <v-row>
        <v-col>
          <p>
            Riff.CC is a collaborative effort to create a free and open-source platform for music,
            videos, and creative content.
          </p><br />
          <p>
            We believe in the power of community and the importance of sharing knowledge and
            experiences.
          </p><br />
          <p>
            The files made available through Riff.CC are made available under legally free licences.
            If you believe something uploaded here does not fit that or is not legal to distribute,
            please contact wings@riff.cc with details, and if it is a DMCA complaint, please put
            "[DMCA]" in the subject line.
          </p><br />
          <p>
            Riff.CC is not affiliated with any other sites, and is not responsible for the content
            of any other Lenses.
          </p><br />
          <p>
            By using Riff.CC you agree to adhere to our policies, especially when uploading content
            or files or interacting with other users.
          </p><br />
          <p>In short:</p>
          <ul>
            <li>You will not upload anything illegal or abusive.</li>
            <li>You will not infringe or cause us to infringe the copyrights of any party.</li>
            <li>
              You will not upload anything you can't give us the ability to legally distribute (it's
              fine to upload Creative Commons material, it's not fine to upload "pirated" material)
            </li>
          </ul><br />
          <p>
            If you have any questions, please contact us at
            <a
              href="mailto:wings@riff.cc"
              class="text-decoration-none text-primary"
            >wings@riff.cc</a>.
          </p>
        </v-col>
      </v-row>
    </p>
  </v-container>
</template>

<script setup lang="ts"></script>
